<template>
  <v-sheet class="wsRoundedHalf pa-6 mt-6">

    <ws-data-table :items="items" :headers="headers">
      <template v-slot:item="{item}">
        <tr>
          <td >
            <ws-chip icon="mdi-calendar" :text="PARSE_TIME_STAMP(item.date_created)" />
            <h5 class="font-weight-light wr">
              {{ getServiceText(item) }}
            </h5>
          </td>
          <td width="10px" class="text-no-wrap">
            <wsChip icon="mdi-cash">
              <h5> {{ item.price }} USD</h5>
            </wsChip>
          </td>
          <td width="10px">
            <v-btn @click="getLiqpayButton(item)" v-if="!item.is_payed" small class="noCaps" dark color="green lighten-1">
              {{ $t('MakePayment')  }}
            </v-btn>
            <v-chip v-else small outlined color="green lighten-1" class="py-3">
              {{ $t('PaymentConfirmed') }}
            </v-chip>
          </td>
        </tr>
      </template>
    </ws-data-table>

  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "activeOrders",
  data() {
    return {
      items : []
    }
  },
  computed : {
    headers() {
      return [
        { text : this.$t('Order') , value : 'order_id' },
        { text : this.$t('Price') , value : 'price' },
        { value : 'is_payed' , sortable : false },
      ]
    }
  },
  methods : {
    ...mapActions('adminPayments', [
      'GET_BUSINESS_ACTIVE_ORDERS',
      'GET_PAYMENT_BUTTON',
    ]),

    async getLiqpayButton(order) {

      let data = order
      order.description = this.getServiceText(order)
      order.return_url = location.href

      let button = await this.GET_PAYMENT_BUTTON(data)
      if ( !button || button === true ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.LIQPAY_checkout(button)
      this.displayEnroll = false
      this.displayEnrollSuccess = true

    },

    // technical
    getServiceText(item) {

      if ( item.is_payment_plan) {
        return `${this.$t('Order')} №${item.order_id},  ${this.$t('PaymentForClientPlan')}: ${item.payment_plan_name} , ${this.$tc('month', item.duration_months, { n : item.duration_months })}`
      }
      if ( item.is_payment_plan_change) {
        return `${this.$t('Order')} №${item.order_id},  ${this.$t('PaymentForClientPlanChange')}: ${item.payment_plan_name} , ${this.$t('surchargeFor')}  ${this.$tc('day', item.duration_months, { n : item.duration_months })}`
      }

      return item.service
    },

    async initPage() {
      let result = await this.GET_BUSINESS_ACTIVE_ORDERS()
      if ( !result || result === true) {
        return
      }
      this.items = result
    }
  },
  mounted() {
    this.initPage()
  }

}
</script>

<style scoped>

</style>